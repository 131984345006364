import React from "react"

import AltLayout from "../components/altlayout"
import SEO from "../components/seo"

import LotOne from "../components/Lots/lotone"
import Footer from "../components/Footer/footer"


const LotOnePage = () => (
  <AltLayout>
    <SEO title="Lot" />
    <LotOne />
    <Footer />
  </AltLayout>
)

export default LotOnePage
